     @import url('../../../assets/plugins/font/openSans.min.css');

     .heading-1 {
         font-size: 20px !important;
         line-height: 1.2;
     }

     .heading-1,
     .heading-2,
     .heading-3,
     .heading-4,
     .heading-6 {
         margin: 0;
         color: #000 !important;
         font-family: 'Open Sans', sans-serif !important;

     }

     h4.heading-4 {
         font-size: 16px !important;
         line-height: 1.3 !important;
         margin-bottom: 10px !important;
     }

     h1:hover::before {
         width: 0;
     }

     h3.heading-3 {
         margin-top: 0px;
         margin-bottom: 10px;
         font-size: 18px;
     }

     img {
         margin: 0 !important;
     }



     .bg {
         background-image: url('../../../assets/images/certificate-bg.webp');
         background-repeat: no-repeat;

         background-size: cover;
         height: 500px;
         border: 1px solid var(--secondary-color);



     }

     .logo1 {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin: 0 25px;
         margin-bottom: 10px;
         padding-top: 10px
     }

     .c-logo1 {
         width: 175px;
         height: auto;

     }

     .c-logo21 {
         width: 90px;
     }

     .certificate-content {
         margin-left: 240px;
         text-align: center;
         margin-top: -10px;
         padding-right: 10px;
         font-family: 'Open Sans', sans-serif !important;

     }

     .text-size {
         font-size: 16px;
         margin-bottom: 10px;
         font-family: 'Open Sans', sans-serif !important;
     }

     .name {

         color: #014da2 !important;
         margin-bottom: 15px;
         font-family: 'Open Sans', sans-serif !important;
     }

     .enroll-no {

         margin-bottom: 15px;
         font-family: 'Open Sans', sans-serif !important;

     }

     .qr-sign {
         display: flex;
         justify-content: space-between;
         align-items: flex-end;
         margin-bottom: 0;
         margin-top: 6%;
         margin-left: 220px;
         padding-right: 10px
     }

     .qr {
         text-align: center;
         padding-left: 21px;
     }

     .qr img {
         width: 64px;

     }

     .qr .heading-6 {
         font-size: 12px;
         font-family: 'Open Sans', sans-serif !important;
     }

     .sign img {
         width: 80px
     }

     .text-center {
         text-align: center
     }

     .w-60 {
         width: 60px !important;
     }

     .small-font {
         font-size: 12px;
         margin-bottom: 5px;
         margin-top: 5px;
         font-family: 'Open Sans', sans-serif !important;
     }

     @media only screen  and (max-width:1199px) {
        .heading-1.name {
       margin-bottom: 0!important;}
       h4.heading-4 {
       font-size: 14px!important;
       line-height: 1.2!important;
       margin-bottom: 4px!important;
   }
   .qr-sign {
       margin-left: 213px!important;
   }
   
   }
    
    @media only screen  and (max-width:1024px) {
   
       .bg {
       height: 390px;}
       .logo1{margin: 0 10px;}
       .c-logo1 {
       width: 100px;
        
   }
   .sign{padding-top:4%}
   .c-logo2 {
       width: 75px;
   }
   .certificate-content {
       margin-left: 175px;}
        h1.heading-1 {
       font-size: 11px!important;
       
   }
   h3.heading-3 {
      
       line-height: 1;
           margin-top: 0px;
       margin-bottom: 0;
       font-size: 11px;
       
   }
   h4.heading-4 {
       font-size: 11px!important;
       line-height: 1.2!important;
       margin-bottom: 0px!important;
   }
   .text-size {
       font-size: 11px;
       margin-bottom: 0px;
       
   }
   .qr-sign {
       
       margin-top: 11px;}
   
    
   .qr-sign {
      
       margin-left: 170px!important;
        
   }
   .qr .heading-6 {
       font-size: 10px;
        
       line-height: 1;
   }
   }
    @media only screen  and (max-width:768px) {
   .qr-sign {
       margin-top: 24px;
   }
   }
   
    @media only screen  and (max-width:767px) {
    .bg {
       height: 450px;
   }
   .certificate-content {
       margin-left: 245px;
   }
   .qr-sign {
       margin-left: 233px!important;
   }
   .text-size {
       font-size: 14px;
        
   }
   h4.heading-4 {
       font-size: 12px!important;}
       .heading-4.enroll-no{
           font-size: 14px!important;
   
       }
       .qr-sign {
       margin-top: 75px;
   }
   
   }
   
@media only screen  and (max-width:575px) {
    .bg {
       height: 282px;
   }
   .c-logo21 {
    width: 30%;
    height: 50%;
   }
   h1.heading-1, h4.heading-4  {
       font-size: 11px!important;
   }
   .text-size {
       font-size: 11px;
        
   }
   h4.heading-4 {
       font-size: 11px!important;}
       .heading-4.enroll-no{
           font-size: 11px!important;
   
       }
   .certificate-content {
       margin-left: 142px;
       padding-top: 5%;
       padding-bottom: 5%;
   }
   .logo {
       
       margin-bottom: 3px;
       padding-top: 3px;
   }
   .qr-sign {
       margin-top: 6px;
   }
   .qr-sign {
       margin-left: 131px!important;
   }
   .sign img {
       width: 50px;
   }
   .small-font {
       font-size: 10px;
       margin-bottom: 0;
       margin-top:3px;
       
       line-height: 1;
   }
   .w-60 {
       width: 40px!important;
   }
   .qr img {
       width: 42px;
   }
   .bg {
       height: 288px;
   }
   .text-size {
       line-height: 1.1;
   }
   .heading-1.name {
       
       font-size: 14px!important;
   }
   
   }
   
    /*responsive 9 oct  end*/